import React, { FC } from "react";
import { Icon } from "../Icon";
import "./index.css";

export const SocialMedia: FC = () => (
  <div className="socials">
    <a href="http://www.facebook.com/bigedsllc/" target="blank">
      <Icon icon="facebook" />
    </a>
    <a
      href="http://www.youtube.com/channel/UClEXt7ieB5upCjUn2a-4mCg"
      target="blank"
    >
      <Icon icon="youtube" />
    </a>
    <a href="http://www.instagram.com/bigedsllc" target="blank">
      <Icon icon="instagram" />
    </a>
    <a href="http://www.yelp.com/biz/big-eds-bbq-waukegan" target="blank">
      <Icon icon="yelp" />
    </a>
  </div>
);
