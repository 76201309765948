import React, { FC } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Catering } from "../Catering";
import { Contact } from "../Contact";
import { Events } from "../Events";
import { Home } from "../Home";
import { Location } from "../Location";
import { Menu } from "../Menu";
import { Press } from "../Press";
///import { Shop } from "../Shop";
import { Story } from "../Story";
import "./index.css";

export const App: FC = () => (
  <Router>
    <Switch>
      <Route path="/catering" component={Catering} />
      <Route path="/contact" component={Contact} />
      <Route path="/events" component={Events} />
      <Route path="/" component={Home} exact />
      <Route path="/location" component={Location} />
      <Route path="/menu" component={Menu} />
      <Route path="/press" component={Press} />
      {/*<Route path="/shop" component={Shop} />*/}
      <Route path="/story" component={Story} />
    </Switch>
  </Router>
);
