import React, { FC } from "react";
import "./index.css";

interface Props {
  image: string;
  name: string;
  title: string;
}

export const StaffMember: FC<Props> = ({ image, name, title }) => (
  <button type="button" className="staff-member">
    <img src={image} alt={name} />
    <div className="staff-member-info">
      <span>{name}</span>
      {title}
    </div>
  </button>
);
