import React, { FC, ReactElement } from "react";
import "./index.css";

interface Props {
  title: string;
  details?: ReactElement;
}

export const Title: FC<Props> = ({ title, details = null }) => (
  <div className="title">
    <div className="title-wrapper">
      <h1 className="title-header">{title}</h1>
      {details}
    </div>
  </div>
);
