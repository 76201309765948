import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";

interface TabProps {
  tabs: Array<{
    id: string;
    text: string;
  }>;
}
export const HeaderNavigation: FC = () => {
  const [active, setActive] = useState<boolean>(false);
  return (
    <nav className="header-nav">
      <Link className="home-link" to="/">
        Big Eds BBQ
      </Link>
      <div className="nav-wrapper">
        <button
          className={`nav-toggle ${active ? "active" : ""}`}
          type="button"
          onClick={(): void => setActive(!active)}
        ></button>
        <div className={`nav-links ${active ? "active" : ""}`}>
          <button className="upserve-olo-opener" type="button">
            Order
          </button>
          <a href="https://swipeit.com/product/2031/big-eds-bbq" target="blank">
            GiftCard
          </a>
          <Link to="/menu">Menu</Link>
          <Link to="/catering">Catering</Link>
        </div>
      </div>
    </nav>
  );
};

export const FooterNavigation: FC = () => (
  <nav className="footer-nav">
    <Link to="/contact">Contact Us</Link>
    <Link to="/story">The Big Ed Story</Link>
    <Link to="/press">Press</Link>
    <Link to="/location">Location</Link>
    <Link to="/events">Events</Link>
    {/*<Link to="/shop">Shop</Link>*/}
  </nav>
);

export const TabNavigation: FC<TabProps> = ({ tabs }) => (
  <div className="tab-nav">
    {tabs.map((tab) => (
      //eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a key={tab.id} href={`#${tab.id}`}>{tab.text}</a>
    ))}
  </div>
);
