import React, { FC } from "react";
import { FooterNavigation } from "../Navigation";
import { SocialMedia } from "../SocialMedia";
import "./index.css";

export const Footer: FC = () => (
  <footer>
    <FooterNavigation />
    <SocialMedia />
  </footer>
);
