import React, { FC } from "react";
import { HeaderNavigation } from "../Navigation";
import "./index.css";

interface Props {
  image: string;
  height: number;
}

export const Header: FC<Props> = ({ image, height }) => (
  <header style={{ backgroundImage: `url(${image})`, height }}>
    <HeaderNavigation />
  </header>
);
