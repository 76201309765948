import React, { FC } from "react";
import { Footer } from "../../Components/Footer";
import { Header } from "../../Components/Header";
import { Title } from "../../Components/Title";
import { Content } from "../../Components/Content";
import { Segment } from "../../Components/Segment";
import HeaderImage from "../../Images/ribtips.jpg";
import "./index.css";

export const Location: FC = () => {
  return (
    <>
      <Header image={HeaderImage} height={400} />
      <Title title="Waukegan" />
      <Content>
        <div className="location">
          <Segment
            border="left"
            content={
              <>
                <h2>Hours Of Operation</h2>
                <div>
                  SUNDAY: <strong>11AM - 7PM</strong>
                </div>
                <div>
                  MONDAY: <strong className="red">CLOSED</strong>
                </div>
                <div>
                  TUESDAY - THURSDAY: <strong>11AM - 9PM</strong>
                </div>
                <div>
                  FRIDAY: <strong>11AM - 10:30PM</strong>
                </div>
                <div>
                  SATURDAY: <strong>11AM - 10PM</strong>
                </div>
              </>
            }
          />
          <div className="location-map-container">
            <iframe
              allowFullScreen={false}
              frameBorder="0"
              height="100%"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.1825486497437!2d-87.90159752379631!3d42.33863123645498!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fed69180439a1%3A0xb7c0c55a64b44a8b!2sBig%20Ed&#39;s%20BBQ%20%26%20Bar!5e0!3m2!1sen!2sus!4v1741302923989!5m2!1sen!2sus"
              title="Location"
              width="100%"
            ></iframe>
          </div>
        </div>
      </Content>
      <Footer />
    </>
)};
