import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Footer } from "../../Components/Footer";
import { Header } from "../../Components/Header";
import { StaffMember } from "../../Components/StaffMember";
import HeaderImage from "../../Images/ribs.jpg";
import Salad from "../../Images/salad.jpg";
import Ribs from "../../Images/bbq.jpg";
import Pie from "../../Images/pie.jpg";
import Eddie from "../../Images/eddie.jpg";
import Kim from "../../Images/kim.jpg";
import Rhonda from "../../Images/rhonda.jpg";
import Katie from "../../Images/katie.jpg";
import Poli from "../../Images/poli.jpg";
import Nicole from "../../Images/nicole.jpg";
import Kevin from "../../Images/kevin.jpg";
import Thalia from "../../Images/thalia.jpg";
import DJ from "../../Images/dj.jpg";
import Melvin from "../../Images/melvin.jpg";
import Isaiah from "../../Images/isaiah.jpg";
import LilEd from "../../Images/liled.jpg";
import LocationImage from "../../Images/location.jpg";
import Logo from "../../Images/logo.jpg";
import "./index.css";

export const Home: FC = () => {
  interface Alert {
    dateStart: string,
    dateEnd: string,
    message: string,
    linkHref: string | null,
    linkMessage: string | null,
  }

  const [alert, setAlert] = useState<Alert | null>(null);

  const getDate = (localeDateString: string): number => new Date(localeDateString).getTime();

  useEffect(() => {
    fetch('alert.json')
    .then(response => response.json())
    .then(data => {
      const now = Date.now()
      const start = getDate(data.dateStart);
      const end = getDate(data.dateEnd);

      if (
        now > start && now < end
      ) {
        setAlert(data)
      }
    });
  }, []);


  return (
  <>
    {alert && <div className="alert-message">
      <span>{alert.message} </span>
      {alert.linkHref && alert.linkMessage && (
        <a href={alert.linkHref} target="_blank" rel="noopener noreferrer">{alert.linkMessage}</a>
      )}
    </div>}
    <Header image={HeaderImage} height={700} />
    <section className="catering-section">
      <Link to="/catering">Cater your next event with Big Ed's</Link>
    </section>
    <section className="menu-section">
      <div className="menu-item" style={{ backgroundImage: `url(${Salad})` }}>
        <Link to="/menu#ss">Chopped Salad</Link>
      </div>
      <div className="menu-item" style={{ backgroundImage: `url(${Ribs})` }}>
        <Link to="/menu#thepit">Baby Back Ribs</Link>
      </div>
      <div className="menu-item" style={{ backgroundImage: `url(${Pie})` }}>
        <Link to="/menu#desserts">Key Lime Pie</Link>
      </div>
    </section>
    <section className="staff-section">
      <h1>Meet The Staff</h1>
      <StaffMember image={Eddie} name="Eddie" title="Pitmaster/Owner" />
      <StaffMember image={Kim} name="Kim" title="Co-Owner" />
      <StaffMember image={Rhonda} name="Rhonda" title="Co-Owner" />
      <StaffMember image={Katie} name="Katie" title="Co-Owner" />
      <StaffMember image={Poli} name="Poli" title="Kitchen Assistant" />
      <StaffMember image={Nicole} name="Nicole" title="Kitchen Assistant" />
      <StaffMember image={Kevin} name="Kevin" title="Kitchen Assistant" />
      <StaffMember image={Thalia} name="Thalia" title="Staff" />
      <StaffMember image={DJ} name="DJ" title="Staff" />
      <StaffMember image={Melvin} name="Melvin" title="Staff" />
      <StaffMember image={Isaiah} name="Isaiah" title="Staff" />
      <StaffMember image={LilEd} name="Lil Ed" title="Staff" />
    </section>
    <section className="media-section">
      <h2>Big Ed's In The Media</h2>
      <iframe
        title="In The Media"
        width="100%"
        height="315"
        src="https://www.youtube.com/embed/videoseries?list=PLnkZIiQ7TzGOSII1EwHwxCkZSSyNjCx0y"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
      ></iframe>
    </section>
    <section
      className="location-section"
      style={{ backgroundImage: `url(${LocationImage})` }}
    >
      <Link to="/location">Visit our Fountain Square Location</Link>
    </section>
    <section className="logo-section">
      <div className="logo-section-wrapper">
        <img src={Logo} alt="Logo" />
        <span className="logo-section-text">
          Made from scratch, seasoned with special dry rub, and slow smoked to
          perfection.
        </span>
      </div>
    </section>
    <Footer />
  </>
  )
}