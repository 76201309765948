import React, { FC, ReactElement } from "react";
import "./index.css";

interface Props {
  border: "left" | "right";
  content: string | ReactElement;
  header?: string;
  id?: string;
  title?: string | ReactElement | null;
}

export const Segment: FC<Props> = ({
  border,
  content,
  header,
  id = undefined,
  title,
}) => (
  <div className="segment" id={id}>
    {header && <h2 className="segment-header">{header}</h2>}
    <div className={`segment-content ${border}`}>
      {title && <p>{title}</p>}
      {content}
    </div>
  </div>
);
