import React, { FC } from "react";
import { Footer } from "../../Components/Footer";
import { Header } from "../../Components/Header";
import { Title } from "../../Components/Title";
import { Content } from "../../Components/Content";
import { Segment } from "../../Components/Segment";
import HeaderImage from "../../Images/ribtips.jpg";
import "./index.css";

export const Location: FC = () => {
  return (
    <>
      <Header image={HeaderImage} height={400} />
      <Title title="Fountain Square" />
      <Content>
        <div className="location">
          <Segment
            border="left"
            content={
              <>
                <h2>Hours Of Operation</h2>
                <div>
                  SUNDAY: <strong>11AM - 7PM</strong>
                </div>
                <div>
                  MONDAY: <strong className="red">CLOSED</strong>
                </div>
                <div>
                  TUESDAY - THURSDAY: <strong>11AM - 9PM</strong>
                </div>
                <div>
                  FRIDAY: <strong>11AM - 10:30PM</strong>
                </div>
                <div>
                  SATURDAY: <strong>11AM - 10PM</strong>
                </div>
              </>
            }
          />
          <div className="location-map-container">
            <iframe
              allowFullScreen={false}
              frameBorder="0"
              height="100%"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5897.660626969957!2d-87.900047!3d42.346141!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880f92584e67aa8b%3A0x5c9c1aaca452603f!2s4030+Northpoint+Blvd%2C+Waukegan%2C+IL+60085!5e0!3m2!1sen!2sus!4v1497812437206"
              title="Location"
              width="100%"
            ></iframe>
          </div>
        </div>
      </Content>
      <Footer />
    </>
)};
