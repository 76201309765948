import React, { FC, useState, useEffect } from "react";
import { Footer } from "../../Components/Footer";
import { Header } from "../../Components/Header";
import { Title } from "../../Components/Title";
import { Content } from "../../Components/Content";
import { Segment } from "../../Components/Segment";
import HeaderImage from "../../Images/ribtips.jpg";
import "./index.css";

export const Press: FC = () => {
  const [pressItems, setPressItems] = useState([]);
  useEffect(() => {
    fetch('press.json')
    .then(response => response.json())
    .then(data => setPressItems(data));
  }, []);

  return (
  <>
    <Header image={HeaderImage} height={400} />
    <Title title="Press" />
    <Content>
      <div className="press">
        {pressItems.map(({ date, organization, link, title }) => (
          <Segment
            border="left"
            content={
              <>
                <h2 className="press-header">{organization}</h2>
                {link ? (
                  <a
                    className="press-link"
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {title}
                  </a>
                ) : (
                  <span className="press-title">{title}</span>
                )}
                <span className="press-date">{date}</span>
              </>
            }
          />
        ))}
      </div>
    </Content>
    <Footer />
  </>
)};
