import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import { Footer } from "../../Components/Footer";
import { Header } from "../../Components/Header";
import { Title } from "../../Components/Title";
import { Content } from "../../Components/Content";
import HeaderImage from "../../Images/ribtips.jpg";
import "./index.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

export const Events: FC = () => {
  interface Popover {
    element: Element| null;
    content?: string;
  }

  const [eventItems, setEventItems] = useState([]);
  const [popOver, setPopover] = useState<Popover>({
    element: null,
    content: undefined,
  });

  const renderEventContent = (eventInfo: any) => {
    const handleEventClick = (event: SyntheticEvent) => {
      setPopover({
        element: popOver.element ? null : event.currentTarget,
        content: popOver.content ? undefined : eventInfo.event.extendedProps.text,
      })
    }

    return (
      <button type="button" className="event-item" onClick={handleEventClick}>
        <strong>{eventInfo.timeText}</strong>
        <span>{eventInfo.event.title}</span>
      </button>
    )
  }

  useEffect(() => {
    fetch('events.json')
    .then(response => response.json())
    .then(data => setEventItems(data));
  }, []);

  document.addEventListener('scroll', () => {
    if (popOver.content) {
      setPopover({
        element: null,
        content: undefined,
      })
    }
  })

  return (
  <> 
    <Header image={HeaderImage} height={400} />
    <Title title="Events" />
    <Content>
      <FullCalendar
        events={eventItems}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        eventContent={renderEventContent}
      />
      {popOver.content && popOver.element && (
        <button
          className="event-content" 
          style={{ 
            top: popOver.element.getBoundingClientRect().top + 25,
            left: popOver.element.getBoundingClientRect().left
          }}
        >
          {popOver.content}
        </button>
      )}
    </Content>
    <Footer />
  </>
)};
