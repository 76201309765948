import React, { FC, useEffect, useState } from "react";
import { Footer } from "../../Components/Footer";
import { Header } from "../../Components/Header";
import { Title } from "../../Components/Title";
import { TabNavigation } from "../../Components/Navigation";
import { Content } from "../../Components/Content";
import { Segment } from "../../Components/Segment";
import HeaderImage from "../../Images/catering.jpg";
import "./index.css";

interface Props {
  category: string;
  note?: string[] | null;
  id?: string;
  items: Array<{
    title: string;
    price: string | null;
    description?: string[] | null;
  }>;
}

export const Catering: FC = () => {
  const [cateringItems, setCateringItems] = useState([]);

  useEffect(() => {
    fetch("catering.json")
      .then((response) => response.json())
      .then((data) => setCateringItems(data));
  }, []);

  return (
    <>
      <Header image={HeaderImage} height={700} />
      <Title
        title="Catering Menu"
        details={
          <>
            <TabNavigation
              tabs={[
                { text: "Combinations", id: "combinations" },
                { text: "Build Your Order", id: "build-your-order" },
                { text: "Desserts", id: "desserts" },
              ]}
            />
            <div className="black-label">
              Let Big Ed’s BBQ handle all of your catering needs for your next
              private party or corporate event. All of our food is made fresh to
              order so please allow 48 hours for your order. There is a ten
              person minimum for all catering orders. Please call (847) 473-5333
              to inquire. Download our full catering menu{" "}
              <a href="/BigEdsBBQCateringMenu.pdf" target="_blank">
                <strong>here</strong>
              </a>
            </div>
          </>
        }
      />
      <Content>
        <div className="catering">
          {cateringItems.map(({ category, note, id, items }: Props) => (
            <Segment
              border="left"
              content={
                <>
                  {items.map(({ title, price, description }) => (
                    <>
                      <div>
                        <strong>
                          {title} {price && `- ${price}`}
                        </strong>
                      </div>
                      {description && (
                        <p>
                          {description.map((d) => (
                            <div>{d}</div>
                          ))}
                        </p>
                      )}
                    </>
                  ))}
                </>
              }
              header={category}
              id={id}
              title={
                note ? (
                  <>
                    {" "}
                    {note.map((n) => (
                      <div>{n}</div>
                    ))}
                  </>
                ) : null
              }
            />
          ))}
        </div>
      </Content>
      <Footer />
    </>
  );
};
