import React, { FC, ReactElement } from "react";
import { FaFacebook, FaInstagram, FaYelp, FaYoutube } from "react-icons/fa";

interface Props {
  icon: "facebook" | "instagram" | "yelp" | "youtube";
}

interface IconMapProps {
  facebook: ReactElement;
  instagram: ReactElement;
  yelp: ReactElement;
  youtube: ReactElement;
}

const IconMap: IconMapProps = {
  facebook: <FaFacebook />,
  instagram: <FaInstagram />,
  yelp: <FaYelp />,
  youtube: <FaYoutube />,
};

export const Icon: FC<Props> = ({ icon }) => IconMap[icon];
