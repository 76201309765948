import React, { FC } from "react";
import { Footer } from "../../Components/Footer";
import { Header } from "../../Components/Header";
import { Title } from "../../Components/Title";
import { Content } from "../../Components/Content";
import { Segment } from "../../Components/Segment";
import HeaderImage from "../../Images/ribtips.jpg";

export const Story: FC = () => (
  <>
    <Header image={HeaderImage} height={400} />
    <Title title="The Big Ed Story" />
    <Content>
      <Segment
        border="left"
        content={
          <>
            <p>
              I live in Waukegan with my wife Kim and I have three children
              Jasmine, Eddie, Isiah and a grandson DJ. The business is owned and
              operated by myself and my three business partners; Rhonda Gage,
              Kim Nero and Katie Sullivan. I am originally from the south side
              of Chicago and I am the youngest of seven children. My parents
              taught me that a man should support his family but he should also
              know how to cook as a way to support his family and himself. So my
              father taught me how to cook at a very young age. I am a disabled
              veteran. In 1988 I was injured in a helicopter crash and later
              honorably discharged from the United States Army.
            </p>
            <p>
              I worked in corporate America for twenty years, attended culinary
              training at Kendall College and in 2000 I formed my own catering
              company, Nero Catering and began catering part time. After being
              downsized October of 2007, I published a book of poetry (Nero
              Rhythms) and began catering full time. Some of my catering clients
              include; The Black Chamber of Commerce of Lake County, The
              Windermere, Abbott, The Pampering Den, Walgreens Health Care,
              Duxler Tire Care, Devin Hester, Jason Mckie, Mark Bradley, Tommie
              Harris, Garrett Wolfe, Brian Urlacher, Lovie Smith, Johnny Knox
              and other members of the Chicago Bears.
            </p>
            <p>
              Now that I told you about myself now let me tell you about my
              food. My restaurant offers some of the best barbeque Lake County
              has tasted in a very long time. All of our meat is seasoned with
              our special dry rub and slow smoked in our barbeque pit with
              seasoned hickory wood. All of our food is made in house from
              scratch daily. Competing in my first BBQ competition I placed 11th
              overall out of forty six teams. I have won best rib tips and
              pulled pork in the North Chicago Rib Cook Off, featured on WGN and
              ABC 7 and voted best BBQ in Chicagoland by 670 The Score.
            </p>
            <p>
              So come out to Big Ed's BBQ. That way when people ask you the
              question, what's that smell? You can tell them that's Big Ed's
              award winning BBQ. Where we have great barbeque and then some. I'm
              looking forward to seeing you real soon!
            </p>
            Big Ed
          </>
        }
      />
    </Content>
    <Footer />
  </>
);
