import React, { FC, useEffect, useState } from "react";
import { Footer } from "../../Components/Footer";
import { Header } from "../../Components/Header";
import { Title } from "../../Components/Title";
import { TabNavigation } from "../../Components/Navigation";
import { Content } from "../../Components/Content";
import { Segment } from "../../Components/Segment";
import HeaderImage from "../../Images/ribtips.jpg";
import "./index.css";

interface Props {
  category: string;
  note?: string | null;
  id?: string;
  items: Array<{
    title: string;
    price: string;
    description?: string[] | null;
  }>;
}

export const Menu: FC = () => {
  const [menuItems, setMenuItems] = useState([]);
  useEffect(() => {
    fetch('menu.json')
    .then(response => response.json())
    .then(data => setMenuItems(data));
  }, []);

  return (
    <>
    <Header image={HeaderImage} height={400} />
    <Title
      title="Menu"
      details={
        <TabNavigation
          tabs={[
            { text: "Appetizers", id: "appetizers" },
            { text: "From The Pit", id: "from-the-pit" },
            { text: "Burgers", id: "burgers" },
            { text: "Sammiches", id: "sammiches" },
            { text: "On The Side", id: "on-the-side" },
            { text: "Soups & Salads", id: "soups-and-salads" },
            { text: "Lil Ed Meals", id: "lil-ed-meals" },
            { text: "Desserts", id: "desserts" },
          ]}
        />
      }
    />
    <Content>
      <div className="menu">
        {menuItems.map(({ category, note, id, items }: Props) => (
          <Segment
            border="left"
            content={
              <div>
                <p>*Prices subject to change</p>
                {items.map(({ title, price, description }) => (
                  <>
                    <div>
                      <strong>{title} - {price}</strong>
                    </div>
                    {description && <p>{description.map(d => <div>{d}</div>)}</p>}
                  </>
                ))}
              </div>
            }
            header={category}
            id={id}
            title={note}
          />
        ))}
      </div>
    </Content>
    <Footer />
  </>
)};
