import React, { FC } from "react";
import { Footer } from "../../Components/Footer";
import { Header } from "../../Components/Header";
import { Title } from "../../Components/Title";
import { Content } from "../../Components/Content";
import HeaderImage from "../../Images/ribtips.jpg";
import "./index.css";

export const Contact: FC = () => (
  <>
    <Header image={HeaderImage} height={400} />
    <Title
      title="Contact Us"
      details={
        <div className="black-label">
          We'd love to hear from you! Please send us your feedback below or call
          us at
          <strong>
            <a href="tel:8474735333"> (847) 473-5333 </a>
          </strong>
        </div>
      }
    />
    <Content>
      <form action="contact.php" method="post">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Your name.."
          required
        />

        <label htmlFor="email">Email Address</label>
        <input
          type="text"
          id="email"
          name="email"
          placeholder="Your email address.."
          required
        />

        <label htmlFor="number">Phone Number</label>
        <input
          type="text"
          id="number"
          name="number"
          placeholder="Your phone number.."
        />

        <label htmlFor="comment">Comments</label>
        <textarea
          id="comment"
          name="comment"
          placeholder="Comments.."
          minLength={10}
          title="Leave a message!"
          spellCheck={true}
        />
        <input type="submit" value="Submit" />
      </form>
    </Content>
    <Footer />
  </>
);
